// projectData.js
const projectData = [
  {
    id: 1,
    category: 'Software',
    ProjectName: "Archiving System",
    ProjectNamearab: "نظام أرشفة",
    ProjectURL:"http://archivestaging.expentech.com/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "The project aims to build an institutional archiving system. The system includes multiple dynamic interfaces to meet the needs and requirements of the institution using it. It assists in creating a wide range of decisions and models used within the institution, making input, access, modification, reading, and retrieval easy at any time. The system can operate on a cloud computing system or an internal network within the institution, ensuring the protection of its data. The system allows for the assignment of permissions and authorizations based on user roles within the institution.",
    projectDescreptionarab: "يهدف المشروع إلى بناء نظام أرشفة مؤسساتي. يتضمن النظام العديد من الواجهات الديناميكية لتناسب احتياجات ومتطلبات المؤسسة المستخدمة لهذا النظام. يساعد النظام على بناء مجموعة واسعة من القرارات والنماذج المستخدمة داخل المؤسسة لتكون سهلة الإدخال والوصول والتعديل والقراءة والاسترجاع في أي وقت. يمكن للنظام العمل على نظام حوسبة سحابية أو على شبكة داخلية للمؤسسة مما يضمن حماية بياناتها يتيح النظام إسناد الصلاحيات والسماحيات حسب أدوار المتسخدمين ضمن المؤسسة.",
    ProjectObjective: "Archiving data and decisions within a secure and user-friendly environment",
    ProjectObjectivearab: "أرشفة البيانات والقرارات ضمن بيئة آمنة وسهلة الاستخدام",
    tools: "Laravel",
    toolsarab: "Laravel",
    timeProject: "400 Hour",
    timeProjectarab: "400 ساعة",
    departments: "Software , IT",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #Management",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/archiving.webp)",
    sliderforimages: false,
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Archive-project.webp",
    projectphotodes: "/images/Archive-project.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 2,
    category: 'Software',
    sliderforimages: false,
    ProjectName: "Khayr Educational Academy",
    ProjectNamearab: "أكاديمية خير التعليمية",
    ProjectURL:"https://khairacademy.org/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "An electronic platform that represents a leading global intellectual system that disseminates religious knowledge and human awareness, seeking to empower Muslim women, families, and the Islamic community based on sound religious principles. Khair Academy for distance education carries a noble goal, which is to spread religious sciences, knowledge, and human experiences that contribute to educating, empowering, and building conscious and educated communities in various humanitarian, scientific, and religious fields. The academy's objectives are education, empowerment, and building.",
    projectDescreptionarab: "منصة إلكترونية تمثل منظومة فكرية عالمية رائدة تنشر العلم الديني والوعي الإنساني وتسعى لتمكين المرأة والأسرة المسلمة والمجتمع الإسلامي حسب قواعد شرعية صحيحة أكاديمية خير للتعليم عن بعد تحمل هدفاً سامياً يتمثل في نشر العلوم الدينية والمعارف والخبرات الإنسانية التي تساهم في تعليم وتمكين وبناء مجتمعات واعية ومثقفة في مختلف المجالات الإنسانية والعلمية والدينية أهداف الأكاديمية تعليم .. تمكين .. بناء",
    ProjectObjective: "Education and Qualification of Students in Islamic Sciences",
    ProjectObjectivearab: "تعليم وتأهيل الطلاب بالعلوم الإسلامية",
    tools: "Laravel – JS - CSS",
    toolsarab: "Laravel – JS - CSS",
    timeProject: "1600 Hour",
    timeProjectarab: "1600  ساعة",
    departments: "Software",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #Education",
    categoryprojectarab: "البرمجيات - التعليم",
    backgroundslide: "url(images/KhairAcademy.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/acadimgdes.webp",
    projectphotodes: "/images/acadimgdes.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 3,
    category: 'Software',
    sliderforimages: false,
    ProjectName: "Educational platform",
    ProjectNamearab: "منصة تعليمية",
    ProjectURL:"https://expertsplus.org/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "This platform targets teachers who wish to offer their courses online to provide their services and expertise remotely. It also caters to students interested in developing their skills and benefiting from the specialized knowledge of these teachers in various fields. The platform believes in providing an easy and clear working environment with a specific goal that allows users to complete educational courses, exams, and related tasks.",
    projectDescreptionarab: "تستهدف هذه المنصة التعليمة الأساتذة الراغبين بنشر دوراتهم على الانترنت لتقديم خدماتهم وخبراتهم عن بعد كما تستهدف المنصة الطلاب المهتمين بتنمية مهاراتهم والاستفادة من خبرات الأساتذة المختصين في مختلف المجالات تؤمن المنصة بيئة عمل سهلة وواضحة وبهدف محدد يسمح للمستخدم بإنجاز الدورات التعليمية والاختبارات والوظائف الخاصة بها.",
    ProjectObjective: "A platform specialized in online teaching",
    ProjectObjectivearab: "منصة مختصة بالتدريس الإلكتروني",
    tools: "Laravel",
    toolsarab: "Laravel",
    timeProject: "200 Hour",
    timeProjectarab: "200 ساعة",
    departments: "Software",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #education",
    categoryprojectarab: "البرمجيات - التعليم",
    backgroundslide: "url(images/Experts.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Expertsplus.webp",
    projectphotodes: "/images/Expertsplus.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 4,
    category: 'Software',
    sliderforimages: false,
    ProjectName: "Lertna cash",
    ProjectNamearab: "ليرتنا كاش",
    ProjectURL:"https://lertnafront.expentech.com/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "يهدف مشروع ليرتنا كاش إلى تسهيل أعمال العاملية بتغذية الحسابات الإلكترونية والموزعين لخدمات الهاتف المحمول والإنترنت تقدم المنصة والتطبيق معاً العديد من الخيارات الخاصة بشركات الهاتف النقالة وخدماتها عالوحدات والفواتير والإنترنت والأرصدة تتيح المنصة لمستخدمي التطبيق بمتابعة تحركاتهم وأنشطتهم اليومية على المنصة، كما تظهر بأي وقت الأرباح المحققة ضمن واجهات منظمة، واضحة وسهلة الاستخدام",
    projectDescreptionarab: "يهدف مشروع ليرتنا كاش إلى تسهيل أعمال العاملية بتغذية الحسابات الإلكترونية والموزعين لخدمات الهاتف المحمول والإنترنت تقدم المنصة والتطبيق معاً العديد من الخيارات الخاصة بشركات الهاتف النقالة وخدماتها عالوحدات والفواتير والإنترنت والأرصدة تتيح المنصة لمستخدمي التطبيق بمتابعة تحركاتهم وأنشطتهم اليومية على المنصة، كما تظهر بأي وقت الأرباح المحققة ضمن واجهات منظمة، واضحة وسهلة الاستخدام",
    ProjectObjective: "Electronic Account Feeding",
    ProjectObjectivearab: "تغذية الحسابات الإلكترونية",
    tools: "Latavel – React JS - Flutter",
    timeProject: "800 Hour",
    timeProjectarab: "800 ساعة",
    departments: "Software",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #Telecommunications",
    categoryprojectarab: "البرمجيات - الاتصالات",
    backgroundslide: "url(images/liretna.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/lirtnaimgdes.webp",
    projectphotodes: "/images/lirtnaimgdes.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 5,
    category: 'Software',
    sliderforimages: false,
    ProjectName: "IJK",
    ProjectNamearab: "IJK",
    ProjectURL:"https://play.google.com/store/apps/details?id=com.esmartvision.ijk&hl=en_NZ&pli=1",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "The project aims to organize trips for a club specializing in off-road desert car adventures, catering to enthusiasts of this sport. The activities of the project include creating and managing these trips, as well as handling registrations. The project operates based on user classification, difficulty levels of the trips, and promotions for users within an enthusiastic and sports-oriented system, providing an enjoyable and exciting sporting experience.",
    projectDescreptionarab: "يهدف المشروع إلى تنظيم رحلات نادي مختص بإقامة الرحلات للسيارات الرملية والمهتمين بهذه الرياضة والنشاط يتضمن المشروع إنشاء الرحلات ومتابعتها والتسجيل فيها يقوم المشروع على أساس تصنيف المستخدمين ومستويات صعوبة الرحلات والترقيات للمستخدمين ضمن نظام مشجع وحماسي ونشاط رياضي ممتع",
    ProjectObjective: "An application for organizing trips for off-road car enthusiasts",
    ProjectObjectivearab: "تطبيق لتنظيم الرحلات لراكبي السيارات الرملية",
    tools: "Laravel – React JS - Flutter",
    toolsarab: "Laravel – React JS - Flutter",
    timeProject: "200 Hour",
    timeProjectarab: "200 ساعة",
    departments: "project , Software , IT , Elctronic",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #sport",
    categoryprojectarab: "البرمجيات - الرياضة",
    backgroundslide: "url(images/IJKmobileapplication.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/IJK-project.webp",
    projectphotodes: "/images/IJK-project.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 6,
    category: 'Software',
    sliderforimages: false,
    ProjectName: "GOOCLUB",
    ProjectNamearab: "GOOCLUB",
    ProjectURL:"https://goooclub.com/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "GOOCLUB is the world's first virtual sports club. The project aims to cater to individuals interested in engaging in virtual sports and provide them with supervision and guidance from sports specialists and nutrition experts.",
    projectDescreptionarab: "منصة GOOCLUB هي أول نادي رياضي افتراضي في العالم يهدف المشروع إلى متابعة الراغبين بممارسة الرياضات افتراضية والمتابعة من قبل الاختصاصيين الرياضيين وإخصائيي التغذية",
    ProjectObjective: "Virtual sports, social, cultural and entertainment club",
    ProjectObjectivearab: "نادي رياضي اجتماعي ثقافي ترفيهي إفتراضي",
    tools: "Wordpress",
    timeProject: "30 Hour",
    timeProjectarab: "30 ساعة",
    departments: "project , Software , IT , Elctronic",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #Digital Marketing",
    categoryprojectarab: "البرمجيات – التسويق الإلكتروني",
    backgroundslide: "url(images/GOOOCLUB.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/GOOOCLUB.webp",
    projectphotodes: "/images/GOOOCLUB.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 1,
    category: 'Homepage',
    sliderforimages: false,
    ProjectName: "Archiving System",
    ProjectNamearab: "نظام أرشفة",
    ProjectURL:"http://archivestaging.expentech.com/",
    ProjectViewButton:true,
    projectDescreption: "The project aims to build an institutional archiving system. The system includes multiple dynamic interfaces to meet the needs and requirements of the institution using it. It assists in creating a wide range of decisions and models used within the institution, making input, access, modification, reading, and retrieval easy at any time. The system can operate on a cloud computing system or an internal network within the institution, ensuring the protection of its data. The system allows for the assignment of permissions and authorizations based on user roles within the institution.",
    projectDescreptionarab: "يهدف المشروع إلى بناء نظام أرشفة مؤسساتي. يتضمن النظام العديد من الواجهات الديناميكية لتناسب احتياجات ومتطلبات المؤسسة المستخدمة لهذا النظام. يساعد النظام على بناء مجموعة واسعة من القرارات والنماذج المستخدمة داخل المؤسسة لتكون سهلة الإدخال والوصول والتعديل والقراءة والاسترجاع في أي وقت. يمكن للنظام العمل على نظام حوسبة سحابية أو على شبكة داخلية للمؤسسة مما يضمن حماية بياناتها يتيح النظام إسناد الصلاحيات والسماحيات حسب أدوار المتسخدمين ضمن المؤسسة.",
    ProjectObjective: "Archiving data and decisions within a secure and user-friendly environment",
    ProjectObjectivearab: "أرشفة البيانات والقرارات ضمن بيئة آمنة وسهلة الاستخدام",
    tools: "Laravel",
    toolsarab: "Laravel",
    timeProject: "400 Hour",
    timeProjectarab: "400 ساعة",
    departments: "Software , IT",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #Management",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/archiving.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Archive-project.webp",
    projectphotodes: "/images/Archive-project.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 2,
    category: 'Homepage',
    ProjectName: "Khayr Educational Academy",
    sliderforimages: false,
    ProjectNamearab: "أكاديمية خير التعليمية",
    ProjectURL:"https://khairacademy.org/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "An electronic platform that represents a leading global intellectual system that disseminates religious knowledge and human awareness, seeking to empower Muslim women, families, and the Islamic community based on sound religious principles. Khair Academy for distance education carries a noble goal, which is to spread religious sciences, knowledge, and human experiences that contribute to educating, empowering, and building conscious and educated communities in various humanitarian, scientific, and religious fields. The academy's objectives are education, empowerment, and building.",
    projectDescreptionarab: "منصة إلكترونية تمثل منظومة فكرية عالمية رائدة تنشر العلم الديني والوعي الإنساني وتسعى لتمكين المرأة والأسرة المسلمة والمجتمع الإسلامي حسب قواعد شرعية صحيحة أكاديمية خير للتعليم عن بعد تحمل هدفاً سامياً يتمثل في نشر العلوم الدينية والمعارف والخبرات الإنسانية التي تساهم في تعليم وتمكين وبناء مجتمعات واعية ومثقفة في مختلف المجالات الإنسانية والعلمية والدينية أهداف الأكاديمية تعليم .. تمكين .. بناء",
    ProjectObjective: "Education and Qualification of Students in Islamic Sciences",
    ProjectObjectivearab: "تعليم وتأهيل الطلاب بالعلوم الإسلامية",
    tools: "Laravel – JS - CSS",
    toolsarab: "Laravel – JS - CSS",
    timeProject: "1600 Hour",
    timeProjectarab: "1600  ساعة",
    departments: "Software",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #Education",
    categoryprojectarab: "البرمجيات - التعليم",
    backgroundslide: "url(images/KhairAcademy.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/acadimgdes.webp",
    projectphotodes: "/images/acadimgdes.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 3,
    category: 'Homepage',
    ProjectName: "Educational platform",
    sliderforimages: false,
    ProjectNamearab: "منصة تعليمية",
    ProjectURL:"https://expertsplus.org/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "This platform targets teachers who wish to offer their courses online to provide their services and expertise remotely. It also caters to students interested in developing their skills and benefiting from the specialized knowledge of these teachers in various fields. The platform believes in providing an easy and clear working environment with a specific goal that allows users to complete educational courses, exams, and related tasks.",
    projectDescreptionarab: "تستهدف هذه المنصة التعليمة الأساتذة الراغبين بنشر دوراتهم على الانترنت لتقديم خدماتهم وخبراتهم عن بعد كما تستهدف المنصة الطلاب المهتمين بتنمية مهاراتهم والاستفادة من خبرات الأساتذة المختصين في مختلف المجالات تؤمن المنصة بيئة عمل سهلة وواضحة وبهدف محدد يسمح للمستخدم بإنجاز الدورات التعليمية والاختبارات والوظائف الخاصة بها.",
    ProjectObjective: "A platform specialized in online teaching",
    ProjectObjectivearab: "منصة مختصة بالتدريس الإلكتروني",
    tools: "Laravel",
    toolsarab: "Laravel",
    timeProject: "200 Hour",
    timeProjectarab: "200 ساعة",
    departments: "Software",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #education",
    categoryprojectarab: "البرمجيات - التعليم",
    backgroundslide: "url(images/Experts.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Expertsplus.webp",
    projectphotodes: "/images/Expertsplus.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 4,
    category: 'Homepage',
    ProjectName: "Lertna cash",
    sliderforimages: false,
    ProjectNamearab: "ليرتنا كاش",
    ProjectURL:"https://lertnafront.expentech.com/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "يهدف مشروع ليرتنا كاش إلى تسهيل أعمال العاملية بتغذية الحسابات الإلكترونية والموزعين لخدمات الهاتف المحمول والإنترنت تقدم المنصة والتطبيق معاً العديد من الخيارات الخاصة بشركات الهاتف النقالة وخدماتها عالوحدات والفواتير والإنترنت والأرصدة تتيح المنصة لمستخدمي التطبيق بمتابعة تحركاتهم وأنشطتهم اليومية على المنصة، كما تظهر بأي وقت الأرباح المحققة ضمن واجهات منظمة، واضحة وسهلة الاستخدام",
    projectDescreptionarab: "يهدف مشروع ليرتنا كاش إلى تسهيل أعمال العاملية بتغذية الحسابات الإلكترونية والموزعين لخدمات الهاتف المحمول والإنترنت تقدم المنصة والتطبيق معاً العديد من الخيارات الخاصة بشركات الهاتف النقالة وخدماتها عالوحدات والفواتير والإنترنت والأرصدة تتيح المنصة لمستخدمي التطبيق بمتابعة تحركاتهم وأنشطتهم اليومية على المنصة، كما تظهر بأي وقت الأرباح المحققة ضمن واجهات منظمة، واضحة وسهلة الاستخدام",
    ProjectObjective: "Electronic Account Feeding",
    ProjectObjectivearab: "تغذية الحسابات الإلكترونية",
    tools: "Latavel – React JS - Flutter",
    timeProject: "800 Hour",
    timeProjectarab: "800 ساعة",
    departments: "Software",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #Telecommunications",
    categoryprojectarab: "البرمجيات - الاتصالات",
    backgroundslide: "url(images/liretna.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/lirtnaimgdes.webp",
    projectphotodes: "/images/lirtnaimgdes.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 5,
    category: 'Homepage',
    sliderforimages: true,
    ProjectName: "E-Fuse",
    ProjectNamearab: "فيوز إلكتروني",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "EXPentech is committed to creating advanced and tailored solutions for you. One such project is the groundbre­aking E-Fuse Circuit, designed to remodel safety and efficiency for your electronic devices. This innovative circuit acts as a vigilant guardian, offering meticulous protection against overcurrent and overvoltage risks. With expert engineering and a touch of brilliance, E-Fuse Circuit ensures seamless power management, enabling your devices to reach their full potential. Trust in EXPentech's dedication to excellence and experience the true power of reliable protection.",
    projectDescreptionarab: "نحن في شركة إكسبينتيك ملتزمون بإنشاء حلول متقدمة ومصممة خصيصًا لك. أحد هذه المشاريع هو دارة E-Fuse المصمم لإعادة تشكيل السلامة والكفاءة لأجهزتك الإلكترونية. تعمل هذه الدائرة المبتكرة كحارس لآلاتك، حيث توفر حماية دقيقة ضد مخاطر التيار الزائد والجهد الزائد. مع المهندسين الخبراء ولمسة من الذكاء، تضمن دارة E-Fuse إدارة سلسة للطاقة، مما يتيح لأجهزتك الوصول إلى إمكاناتها الكاملة. ثق في تفاني شركتنا في التميز واختبر القوة الحقيقية للحماية الموثوقة",
    ProjectObjective: "Meticulous protection against overcurrent and overvoltage risks.",
    ProjectObjectivearab: "حماية دقيقة ضد مخاطر زيادة التيار والجهد.",
    tools: "PCB",
    toolsarab: "دارات مطبوعة",
    timeProject: "75 Hour",
    timeProjectarab: "75 ساعة",
    departments: "ELECTRONICS",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/FUSE.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics3.webp",
    projectphotodes: "/images/Electronics3.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/3D_1_1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/3D_1_2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/pcb_1.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      }
    ]
  },
  {
    id: 6,
    category: 'Homepage',
    sliderforimages: true,
    ProjectName: "Voltage multiplier circuit",
    ProjectNamearab: "دارة ضارب الجهد",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "EXPentech introduces its groundbreaking Voltage Multiplier Circuit. With the capability to elevate voltage levels from 400 V AC to an astonishing 20 kV DC, this cutting-edge­ circuitry ensures unparalleled efficiency and reliability. From industrial applications to scientific and research ende­avors, our state-of-the-art solution empowers seamless power amplification across diverse fields. Trust our team of experts to provide you with a compact and dependable solution perfectly tailored to your specific voltage requirements.",
    projectDescreptionarab: "تقدم شركتنا، إكسبينتيك؛ دارتها الخاصة لضارب الجهد Voltage Multiplier. ترفع وتحول هذه الدارة الجهد المتناوب بقيمة 400 فولت إلى 20 كيلو فولت مستمر. تضمن دارتنا التكنولوجية المتقدمة كفاءة وموثوقية عالية لتناسب عملك من التطبيقات الصناعية إلى الأعمال العلمية والبحثية. نمنحك حلاً حديثاً ليمكّن من التضخيم المثالي للطاقة في مجموعة متنوعة من المجالات. ثق بفريقنا من الخبراء لتوفير الحل المدمج والموثوق الذي يتماشى بشكل مثالي مع المتطلبات الخاصة بك.",
    ProjectObjective: "converts AC electrical power from a lower voltage to a higher DC voltage",
    ProjectObjectivearab: "محول للطاقة الكهربائية المتناوبة من جهد منخفض إلى جهد مستمر أعلى",
    tools: "PCB, AC-DC Converter topology",
    toolsarab: "دارات مطبوعة، بنية محول الجهد المتناوب إلى مستمر",
    timeProject: "60 Hour",
    timeProjectarab: "60 ساعة",
    departments: "ELECTRONICS",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/Voltagemultipliecircuit.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics1.webp",
    projectphotodes: "/images/Electronics1.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/1.jpeg",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/2.jpeg",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/3.jpeg",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/4.jpeg",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/5.jpeg",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 7,
    category: 'Homepage',
    sliderforimages: true,
    ProjectName: "Chocolate Manufacturing Machines",
    ProjectNamearab: "آلات تصنيع الشوكولا",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "Precision and efficiency play a role, in the world of confectionery, where the goal's to craft delightful treats. Factories rely on chocolate machines and specialized equipment, which incorporate cutting-edge technology and automated packaging to bring this artistry to life. By leveraging these tools confectionery manufacturers can fulfill demands while still preserving the love and craftsmanship that goes into each creation.Here, at EXPentech we are excited to introduce our endeavor. The Ball Mill machine and Conching machine, for Chocolate Manufacturing process. These machines have been meticulously designed with a combination of expertise and enthusiasm representing cutting-edge chocolate manufacturing technology. They incorporate top-notch stainless-steel components that prioritize both food safety and exceptional performance",
    projectDescreptionarab: "تلعب الدقة والكفاءة دورًا في عالم صناعة الحلويات، تعتمد المصانع على ماكينات الشوكولا والمعدات المتخصصة، والتي تتضمن أحدث التقنيات والتعبئة الآلية لإضفاء الحيوية على هذا الفن. من خلال الاستفادة من هذه الأدوات، يمكن لمصنعيّ الحلويات تلبية المطالب مع الحفاظ على الحب والحرفية التي تدخل في كل إبداع. في شركة إكسبينتيك، نحن متحمسون لتقديم مسعانا في مجال تقديم آلات تصنيع الشوكولا من آلات كونج وآلات الطحن بالفينير بالحجم المناسب لعملكم. يتم تصميم هذه الآلات بدقة مع مزيج من الخبرة والحماس يمثلان أحدث تقنيات تصنيع الشوكولا. آلاتنا مصنعة من مواد كروم أو ستانلس ستيل غذائي الدرجة الأولى لتعطي الأولوية لسلامة الأغذية والأداء الاستثنائي. أبدأ مشروعك الخاص بمجال تصنيع الشوكولا السائلة معنا وفق احتياجاتك وطلباتك.",
    ProjectObjective: "Chocolate Manufacturing with High-Quality Stainless Steel Ball Mill Machine and Conching Machine.",
    ProjectObjectivearab: "تصنيع الشوكولاتة باستخدام آلة طحن بالكرات (آلات الفينير) وآلة الكونج عاليً الجودة المصنعين بالستانلس ستيل الغذائي.",
    tools: "Ball Mill Machine, Conching Machine, Automatic Control, Mechanical Design",
    toolsarab: "آلات كونج، آلات الطحن بالفينير، تحكم آلي، تصميم ميكانيكي",
    timeProject: "400 Hour",
    timeProjectarab: "400 ساعة",
    departments: "Mechanical, Automatic Control",
    departmentsarab: "ميكانيك، تحكم آلي",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/Choco.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics4.webp",
    projectphotodes: "/images/Electronics4.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/chocolate1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate3.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate4.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate5.webp",
        titleslide: "Photo Name 5",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate6.webp",
        titleslide: "Photo Name 6",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 8,
    category: 'Homepage',
    sliderforimages: true,
    ProjectName: "Croissant roll machine",
    ProjectNamearab: "آلة لف الكروسان",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "EXPentech, our company known for transformative projects, is currently engaged in an exciting endeavor to revolutionize croissant production. With our scientific expertise at the forefront, we are utilizing cutting-edge modifications to enhance productivity along the production line. By seamlessly integrating the dough mixer and filling machine through automated processes, we have successfully streamlined the entire process while optimizing output like never before. Our commitment to innovation is evident in the meticulous design of a tailormade croissant folding machine that significantly amplifies efficiency throughout both stages of the production process.EXPentech's commitment to precision engineering paves the way for your manufacturing process to new horizons, where excellence in technology meets the art of indulgence.",
    projectDescreptionarab: "يسر شركتنا أن تشارك معكم مشروعها الجديد لتعديل أحد خطوط إنتاج الكروسان. بفضل خبرتنا العلمية البارزة، تم إجراء تعديلات متطورة لتعزيز الإنتاجية على طول خط الإنتاج من خلال دمج عملي ومؤتمت بين العجانة وماكينة الحشو. حيث تم تبسيط عملية الإنتاج بنجاح وزيادة الإنتاجية. يأتي التحسين بتصميم آلة لف جديدة، بين العجانة وآلة الحشو المخصصة، التي تعزز الكفاءة بشكل كبير في كل مرحلة من مراحل الإنتاج. يمهد التزام شركة إكسبينتيك بالهندسة الدقيقة الطريق لعملية التصنيع الخاصة بك إلى آفاق جديدة، حيث يلتقي تميزك مع الطرق التكنولوجية المبتكرة.",
    ProjectObjective: "EXPentech enhances croissant production by optimizing the line with automated dough-to-filling connection, and introducing a specialized interleaver machine, boosting productivity.",
    ProjectObjectivearab: "تطوير خط الإنتاج بطريقة ابتكارية من شركة إكسبينتيك: تحسين خط إنتاج الكروسان بتعديلات تزيد من إنتاجيته، وتقوم بتوصيل العجانة بآلة الحشو بشكل آلي ومؤتمت، وتزيد من الإنتاج عبر تصنيع آلة لف للكروسان.",
    tools: "Croissant roll machine, Automatic Control, Mechanical Design",
    toolsarab: "آلات لف للكروسان، تحكم آلي، تصميم ميكانيكي",
    timeProject: "600 Hour",
    timeProjectarab: "600 ساعة",
    departments: "Mechanical, Automatic Control",
    departmentsarab: "ميكانيك، تحكم آلي",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/Croissant.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics5.webp",
    projectphotodes: "/images/Electronics5.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/croissent1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent3.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent4.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent5.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent6.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent7.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 9,
    category: 'Homepage',
    sliderforimages: false,
    ProjectName: "IJK",
    ProjectNamearab: "IJK",
    ProjectURL:"https://play.google.com/store/apps/details?id=com.esmartvision.ijk&hl=en_NZ&pli=1",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "The project aims to organize trips for a club specializing in off-road desert car adventures, catering to enthusiasts of this sport. The activities of the project include creating and managing these trips, as well as handling registrations. The project operates based on user classification, difficulty levels of the trips, and promotions for users within an enthusiastic and sports-oriented system, providing an enjoyable and exciting sporting experience.",
    projectDescreptionarab: "يهدف المشروع إلى تنظيم رحلات نادي مختص بإقامة الرحلات للسيارات الرملية والمهتمين بهذه الرياضة والنشاط يتضمن المشروع إنشاء الرحلات ومتابعتها والتسجيل فيها يقوم المشروع على أساس تصنيف المستخدمين ومستويات صعوبة الرحلات والترقيات للمستخدمين ضمن نظام مشجع وحماسي ونشاط رياضي ممتع",
    ProjectObjective: "An application for organizing trips for off-road car enthusiasts",
    ProjectObjectivearab: "تطبيق لتنظيم الرحلات لراكبي السيارات الرملية",
    tools: "Laravel – React JS - Flutter",
    toolsarab: "Laravel – React JS - Flutter",
    timeProject: "200 Hour",
    timeProjectarab: "200 ساعة",
    departments: "project , Software , IT , Elctronic",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #sport",
    categoryprojectarab: "البرمجيات - الرياضة",
    backgroundslide: "url(images/IJKmobileapplication.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/IJK-project.webp",
    projectphotodes: "/images/IJK-project.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 10,
    category: 'Homepage',
    sliderforimages: false,
    ProjectName: "GOOCLUB",
    ProjectNamearab: "GOOCLUB",
    ProjectURL:"https://goooclub.com/",
    ProjectViewButton:true,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "GOOCLUB is the world's first virtual sports club. The project aims to cater to individuals interested in engaging in virtual sports and provide them with supervision and guidance from sports specialists and nutrition experts.",
    projectDescreptionarab: "منصة GOOCLUB هي أول نادي رياضي افتراضي في العالم يهدف المشروع إلى متابعة الراغبين بممارسة الرياضات افتراضية والمتابعة من قبل الاختصاصيين الرياضيين وإخصائيي التغذية",
    ProjectObjective: "Virtual Sports Club",
    ProjectObjectivearab: "نادي رياضي إفتراضي",
    tools: "Wordpress",
    timeProject: "30 Hour",
    timeProjectarab: "30 ساعة",
    departments: "project , Software , IT , Elctronic",
    departmentsarab: "البرمجيات",
    categoryproject: "#Software #Digital Marketing #sport",
    categoryprojectarab: "البرمجيات – التسويق الإلكتروني - الرياضة",
    backgroundslide: "url(images/GOOOCLUB.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/GOOOCLUB.webp",
    projectphotodes: "/images/GOOOCLUB.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 2,
    category: 'Electronics',
    sliderforimages: true,
    ProjectName: "E-Fuse",
    ProjectNamearab: "فيوز إلكتروني",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "EXPentech is committed to creating advanced and tailored solutions for you. One such project is the groundbre­aking E-Fuse Circuit, designed to remodel safety and efficiency for your electronic devices. This innovative circuit acts as a vigilant guardian, offering meticulous protection against overcurrent and overvoltage risks. With expert engineering and a touch of brilliance, E-Fuse Circuit ensures seamless power management, enabling your devices to reach their full potential. Trust in EXPentech's dedication to excellence and experience the true power of reliable protection.",
    projectDescreptionarab: "نحن في شركة إكسبينتيك ملتزمون بإنشاء حلول متقدمة ومصممة خصيصًا لك. أحد هذه المشاريع هو دارة E-Fuse المصمم لإعادة تشكيل السلامة والكفاءة لأجهزتك الإلكترونية. تعمل هذه الدائرة المبتكرة كحارس لآلاتك، حيث توفر حماية دقيقة ضد مخاطر التيار الزائد والجهد الزائد. مع المهندسين الخبراء ولمسة من الذكاء، تضمن دارة E-Fuse إدارة سلسة للطاقة، مما يتيح لأجهزتك الوصول إلى إمكاناتها الكاملة. ثق في تفاني شركتنا في التميز واختبر القوة الحقيقية للحماية الموثوقة",
    ProjectObjective: "Meticulous protection against overcurrent and overvoltage risks.",
    ProjectObjectivearab: "حماية دقيقة ضد مخاطر زيادة التيار والجهد.",
    tools: "PCB",
    toolsarab: "دارات مطبوعة",
    timeProject: "75 Hour",
    timeProjectarab: "75 ساعة",
    departments: "ELECTRONICS",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/FUSE.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics3.webp",
    projectphotodes: "/images/Electronics3.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/3D_1_1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/3D_1_2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/pcb_1.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      }
    ]
  },
  {
    id: 3,
    category: 'Electronics',
    sliderforimages: true,
    ProjectName: "Voltage multiplier circuit",
    ProjectNamearab: "دارة ضارب الجهد",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "EXPentech introduces its groundbreaking Voltage Multiplier Circuit. With the capability to elevate voltage levels from 400 V AC to an astonishing 20 kV DC, this cutting-edge­ circuitry ensures unparalleled efficiency and reliability. From industrial applications to scientific and research ende­avors, our state-of-the-art solution empowers seamless power amplification across diverse fields. Trust our team of experts to provide you with a compact and dependable solution perfectly tailored to your specific voltage requirements.",
    projectDescreptionarab: "تقدم شركتنا، إكسبينتيك؛ دارتها الخاصة لضارب الجهد Voltage Multiplier. ترفع وتحول هذه الدارة الجهد المتناوب بقيمة 400 فولت إلى 20 كيلو فولت مستمر. تضمن دارتنا التكنولوجية المتقدمة كفاءة وموثوقية عالية لتناسب عملك من التطبيقات الصناعية إلى الأعمال العلمية والبحثية. نمنحك حلاً حديثاً ليمكّن من التضخيم المثالي للطاقة في مجموعة متنوعة من المجالات. ثق بفريقنا من الخبراء لتوفير الحل المدمج والموثوق الذي يتماشى بشكل مثالي مع المتطلبات الخاصة بك.",
    ProjectObjective: "converts AC electrical power from a lower voltage to a higher DC voltage",
    ProjectObjectivearab: "محول للطاقة الكهربائية المتناوبة من جهد منخفض إلى جهد مستمر أعلى",
    tools: "PCB, AC-DC Converter topology",
    toolsarab: "دارات مطبوعة، بنية محول الجهد المتناوب إلى مستمر",
    timeProject: "60 Hour",
    timeProjectarab: "60 ساعة",
    departments: "ELECTRONICS",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/Voltagemultipliecircuit.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics1.webp",
    projectphotodes: "/images/Electronics1.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/3.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/4.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/5.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 4,
    category: 'Electronics',
    sliderforimages: true,
    ProjectName: "Chocolate Manufacturing Machines",
    ProjectNamearab: "آلات تصنيع الشوكولا",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "Precision and efficiency play a role, in the world of confectionery, where the goal's to craft delightful treats. Factories rely on chocolate machines and specialized equipment, which incorporate cutting-edge technology and automated packaging to bring this artistry to life. By leveraging these tools confectionery manufacturers can fulfill demands while still preserving the love and craftsmanship that goes into each creation.Here, at EXPentech we are excited to introduce our endeavor. The Ball Mill machine and Conching machine, for Chocolate Manufacturing process. These machines have been meticulously designed with a combination of expertise and enthusiasm representing cutting-edge chocolate manufacturing technology. They incorporate top-notch stainless-steel components that prioritize both food safety and exceptional performance",
    projectDescreptionarab: "تلعب الدقة والكفاءة دورًا في عالم صناعة الحلويات، تعتمد المصانع على ماكينات الشوكولا والمعدات المتخصصة، والتي تتضمن أحدث التقنيات والتعبئة الآلية لإضفاء الحيوية على هذا الفن. من خلال الاستفادة من هذه الأدوات، يمكن لمصنعيّ الحلويات تلبية المطالب مع الحفاظ على الحب والحرفية التي تدخل في كل إبداع. في شركة إكسبينتيك، نحن متحمسون لتقديم مسعانا في مجال تقديم آلات تصنيع الشوكولا من آلات كونج وآلات الطحن بالفينير بالحجم المناسب لعملكم. يتم تصميم هذه الآلات بدقة مع مزيج من الخبرة والحماس يمثلان أحدث تقنيات تصنيع الشوكولا. آلاتنا مصنعة من مواد كروم أو ستانلس ستيل غذائي الدرجة الأولى لتعطي الأولوية لسلامة الأغذية والأداء الاستثنائي. أبدأ مشروعك الخاص بمجال تصنيع الشوكولا السائلة معنا وفق احتياجاتك وطلباتك.",
    ProjectObjective: "Chocolate Manufacturing with High-Quality Stainless Steel Ball Mill Machine and Conching Machine.",
    ProjectObjectivearab: "تصنيع الشوكولاتة باستخدام آلة طحن بالكرات (آلات الفينير) وآلة الكونج عاليً الجودة المصنعين بالستانلس ستيل الغذائي.",
    tools: "Ball Mill Machine, Conching Machine, Automatic Control, Mechanical Design",
    toolsarab: "آلات كونج، آلات الطحن بالفينير، تحكم آلي، تصميم ميكانيكي",
    timeProject: "400 Hour",
    timeProjectarab: "400 ساعة",
    departments: "Mechanical, Automatic Control",
    departmentsarab: "ميكانيك، تحكم آلي",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/Choco.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics4.webp",
    projectphotodes: "/images/Electronics4.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/chocolate1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate3.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate4.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate5.webp",
        titleslide: "Photo Name 5",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/chocolate6.webp",
        titleslide: "Photo Name 6",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 5,
    category: 'Electronics',
    sliderforimages: true,
    ProjectName: "Croissant roll machine",
    ProjectNamearab: "آلة لف الكروسان",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "EXPentech, our company known for transformative projects, is currently engaged in an exciting endeavor to revolutionize croissant production. With our scientific expertise at the forefront, we are utilizing cutting-edge modifications to enhance productivity along the production line. By seamlessly integrating the dough mixer and filling machine through automated processes, we have successfully streamlined the entire process while optimizing output like never before. Our commitment to innovation is evident in the meticulous design of a tailormade croissant folding machine that significantly amplifies efficiency throughout both stages of the production process.EXPentech's commitment to precision engineering paves the way for your manufacturing process to new horizons, where excellence in technology meets the art of indulgence.",
    projectDescreptionarab: "يسر شركتنا أن تشارك معكم مشروعها الجديد لتعديل أحد خطوط إنتاج الكروسان. بفضل خبرتنا العلمية البارزة، تم إجراء تعديلات متطورة لتعزيز الإنتاجية على طول خط الإنتاج من خلال دمج عملي ومؤتمت بين العجانة وماكينة الحشو. حيث تم تبسيط عملية الإنتاج بنجاح وزيادة الإنتاجية. يأتي التحسين بتصميم آلة لف جديدة، بين العجانة وآلة الحشو المخصصة، التي تعزز الكفاءة بشكل كبير في كل مرحلة من مراحل الإنتاج. يمهد التزام شركة إكسبينتيك بالهندسة الدقيقة الطريق لعملية التصنيع الخاصة بك إلى آفاق جديدة، حيث يلتقي تميزك مع الطرق التكنولوجية المبتكرة.",
    ProjectObjective: "EXPentech enhances croissant production by optimizing the line with automated dough-to-filling connection, and introducing a specialized interleaver machine, boosting productivity.",
    ProjectObjectivearab: "تطوير خط الإنتاج بطريقة ابتكارية من شركة إكسبينتيك: تحسين خط إنتاج الكروسان بتعديلات تزيد من إنتاجيته، وتقوم بتوصيل العجانة بآلة الحشو بشكل آلي ومؤتمت، وتزيد من الإنتاج عبر تصنيع آلة لف للكروسان .",
    tools: "Croissant roll machine, Automatic Control, Mechanical Design",
    toolsarab: "آلات لف للكروسان، تحكم آلي، تصميم ميكانيكي",
    timeProject: "600 Hour",
    timeProjectarab: "600 ساعة",
    departments: "Mechanical, Automatic Control",
    departmentsarab: "ميكانيك، تحكم آلي",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/Croissant.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Electronics5.webp",
    projectphotodes: "/images/Electronics5.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/croissent1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent3.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent4.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent5.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent6.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/croissent7.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 1,
    category: 'It',
    ProjectName: "",
    ProjectNamearab: "",
    projectDescreption: "",
    projectDescreptionarab: "",
    ProjectObjective: "",
    tools: "",
    timeProject: "",
    timeProjectarab: "",
    departments: "project , Software , IT , Elctronic",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/NoPath.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Group47511.webp",
    projectphotodes: "/images/Group47511.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 2,
    category: 'It',
    ProjectName: "",
    ProjectNamearab: "",
    projectDescreption: "",
    projectDescreptionarab: "",
    ProjectObjective: "",
    tools: "",
    timeProject: "",
    timeProjectarab: "",
    departments: "project , Software , IT , Elctronic",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/NoPath.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Group47511.webp",
    projectphotodes: "/images/Group47511.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 3,
    category: 'It',
    ProjectName: "",
    ProjectNamearab: "",
    projectDescreption: "",
    projectDescreptionarab: "",
    ProjectObjective: "",
    tools: "",
    timeProject: "",
    timeProjectarab: "",
    departments: "project , Software , IT , Elctronic",
    departmentsarab: "البرمجيات – تقانة المعلومات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/NoPath.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/Group47511.webp",
    projectphotodes: "/images/Group47511.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/MaskGroup83.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Group500.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  },
  {
    id: 1,
    category: 'Unitech',
    sliderforimages: true,
    ProjectName: "Earthquake rescue robot",
    ProjectNamearab: "روبوت الإنقاذ لضحايا الزلازل",
    ProjectViewButton:false,
    ProjectArticleURL:"https://expentech.com/blog/en/blog/",
    ProjectArticleButton: false,
    projectDescreption: "The UniTech department at EXPentech takes great pride in its groundbreaking projects, such as the Earthquake Rescue Robot. This innovative robot, designed with a snake­like structure and equipped with cutting-edge technologies including Digital Control Algorithms, Mechanical Design, Remote Control, Audio and Image Processing, and Internet of Things integration, revolutionizes disaster response capabilities. With its ability to swiftly navigate disaster areas, this versatile ground mobile robot empowers rescue teams with real-time data and remote-control functionalities. The Earthquake Rescue Robot stands out as a trailblazer in enhancing safety and efficiency during critical search and rescue operation.",
    projectDescreptionarab: "يفخر قسم UniTech في شركة إكسبينتيك بمشاريعه الرائدة، مثل روبوت الإنقاذ في حالات الزلازل. هذا الروبوت المبتكر، المصمم بهيكل يشبه الثعبان ومجهز بأحدث التقنيات بما في ذلك خوارزميات التحكم الرقمي، التصميم الميكانيكي، التحكم عن بعد، معالجة الصوت والصورة وتكامله مع إنترنت الأشياء بحيث يُحدث ثورة في قدرات الاستجابة للكوارث. بفضل قدرته على التنقل بسرعة في مناطق الكوارث، يعمل هذا الروبوت المتنقل الأرضي على تمكين فرق الإنقاذ ببيانات بالزمن الحقيقي ويسمح لهم بالوصول السريع للضحايا بالتحكم عن بعد. يبرز روبوت إنقاذ الزلزال كعمل رائد في تعزيز السلامة والكفاءة أثناء عمليات البحث والإنقاذ المهمة",
    ProjectObjective: "Enhancing Disaster Response Capabilities - Developing an Earthquake Rescue Robot with Multifaceted Ground Mobility and Advanced Technologies.",
    ProjectObjectivearab: "تعزيز قدرات الاستجابة للكوارث - تطوير روبوت لإنقاذ الزلازل مع التنقل الأرضي متعدد الأوجه والتقنيات المتقدمة.",
    tools: "Digital Control Algorithms, Mechanical Design, Remote Control, Audio and Image Processing, Internet of Things",
    toolsarab: "خوارزميات تحكم رقمي، تصميم ميكانيكي، تحكم عن بعد، معالجة صوت وصورة، إنترنت الأشياء",
    timeProject: "400 Hour",
    timeProjectarab: "400 ساعة",
    departments: "Mechanical, Electronic, Communications",
    departmentsarab: "ميكانيك، إلكترون، اتصالات",
    categoryproject: "#Software #IT",
    categoryprojectarab: "البرمجيات - الإدارة",
    backgroundslide: "url(images/ezgif-5-a4af773245.webp)",
    image1: "/images/department-svgrepo-com.webp",
    pojectIcon2: "/images/Line67.webp",
    pojectIcon3: "/images/category-svgrepo-com.webp",
    pojectImageRight: "/images/unitech1.webp",
    projectphotodes: "/images/unitech1.webp",
    leftside: "/images/vuesax-left.webp",
    rightside: "/images/vuesax-right.webp",
    logo: "/images/logo-home.webp",
    biglogo: "/images/logo.webp",
    closecircle: "/images/vuesax-linear-close-circle.webp",
    positionimg: "/images/Group47584.webp",
    positionimgarab: "/images/G84.webp",
    emailimgarab: "/images/Grou83.webp",
    emailimg: "/images/Group47583.webp",
    teleimgarab: "/images/Grou47582.webp",
    teleimg: "/images/Group47582.webp",
    sliderData2: [
      {
        imageslide: "/images/Earthquake1.webp",
        titleslide: "Photo Name 1",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Earthquake2.webp",
        titleslide: "Photo Name 2",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Earthquake3.webp",
        titleslide: "Photo Name 3",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
      {
        imageslide: "/images/Earthquake4.webp",
        titleslide: "Photo Name 4",
        titleslidearab: "اسم الصورة",
        explanslide:
          "Sed ut perspiciatis unde omnis iste natus errorsit voluptatem accusantium dolorer emque laudantium, totam rem aperiam, eaque ipsaquae abillo inventore veritatiq s et quasi architecto beataevitae dictasunt explicabo. Nemo enim ipsam vouptatr em quia voluptas sit aspernaturm quia voluptas sit aspernat. g",
        explanslidearab: "تمكين عملك من خلال التكنولوجيا ، تقدم شركتنا EXPentech مجموعة واسعة من حلول التكنولوجيا الهندسية بما في ذلك الخدمات الميكانيكية والإلكترونية والكهربائية والاتصالات البرمجية وخدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم. هدفنا هو مساعدتك على تحقيق النجاح والنمو من خلال الاستفادة من التكنولوجيا الحديثة والخبرة الهندسية في عصر يحركه التكنولوجيا. خدمات هندسة تكنولوجيا المعلومات. مع فريق من أفضل المهندسين في الدولة ، دعونا نضع بين أيديكم الحلول المتكاملة التي تلبي احتياجاتكم",
      },
    ]
  }
];

export default projectData;
