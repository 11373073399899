import './App.css';
import { useState, Suspense, lazy, useEffect } from 'react';

/* import HomePage from "./pages/HomePage/HomePage";
import Software from "./pages/Software/Software";
import Electronics from "./pages/Electronics/Electronics";
import It from "./pages/It/It";
import Unitech from "./pages/UniTech/UniTech";
import ContactUs from "./pages/ContactUs/ContactUs";
import Project from "./pages/Project/Project"; */
import projectData from "./projectData";
import { BrowserRouter, Route, Routes } from 'react-router-dom';


const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const Software = lazy(() => import('./pages/Software/Software'));
const Electronics = lazy(() => import('./pages/Electronics/Electronics'));
const It = lazy(() => import('./pages/It/It'));
const Unitech = lazy(() => import('./pages/UniTech/UniTech'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const Project = lazy(() => import('./pages/Project/Project'));

function App() {

  const initialLanguagePreference = localStorage.languagePreference ? localStorage.languagePreference : 'arabic'
  const [isArabicMode, setIsArabicMode] = useState(initialLanguagePreference ==='arabic');
  
  useEffect(() => {
    localStorage.setItem('languagePreference', isArabicMode ? 'arabic' : 'english');
  }, [isArabicMode]);
 
  const toggleMode = () => {
    setIsArabicMode((prevMode) => !prevMode);
  };
  function capitalizeFirstLetter(str) {
    if (typeof str !== 'string' || str.length === 0) {
      return str; // Return the original value if it's not a non-empty string
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleProjectClick = (category, projectId ) => {
    const capitalizedCategory = capitalizeFirstLetter(category);
    window.location.href = `/${capitalizedCategory}/projects/${projectId}`;
  };

  
  
  function Loading() {
    return  <img src='images/logo.webp' style={{ padding: "10%" }} />;
  }
  
  return (
    <div className="App" style={{ direction: isArabicMode ? "rtl" : ''  , fontFamily : isArabicMode ? "Cairo" : "" }}>
      <header className="App-header">
        <Suspense fallback={<Loading />}>
          <BrowserRouter basename="/">
            <Routes>
              <Route path="/" element={<HomePage projectData={projectData} category='Homepage' handleProjectClick={handleProjectClick} isArabicMode={isArabicMode} toggleMode={toggleMode} />} />
              <Route path="/Software" element={<Software projectData={projectData} category='Software' handleProjectClick={handleProjectClick} isArabicMode={isArabicMode} toggleMode={toggleMode} />} />
              <Route path="/Electronics" element={<Electronics projectData={projectData} category='Electronics' handleProjectClick={handleProjectClick} isArabicMode={isArabicMode} toggleMode={toggleMode} />} />
              <Route path="/IT" element={<It projectData={projectData} category='It' handleProjectClick={handleProjectClick} isArabicMode={isArabicMode} toggleMode={toggleMode} />} />
              <Route path="/Unitech" element={<Unitech projectData={projectData} category='Unitech' handleProjectClick={handleProjectClick} isArabicMode={isArabicMode} toggleMode={toggleMode} />} />
              <Route path="/ContactUs" element={<ContactUs isArabicMode={isArabicMode} toggleMode={toggleMode} />} />
              <Route path="/:category/projects/:id" element={<Project projectData={projectData} isArabicMode={isArabicMode} toggleMode={toggleMode} />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </header>
    </div >
  );
}

export default App;
